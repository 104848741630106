.login-page {
    .login-form {
        padding: 30vh 0;
        .card {
            text-align: center;
            .alert {
                text-align: left;
                width: 100%;
            }
            p {
                width: 100%;
            }
            .logo {
                max-width: 200px;
            }
            h1 {
                color: $colorDark;
            }
            background-color: $colorGold;
            min-height: 200px;
        }
    }
}